import React, { useState, useCallback } from 'react';

import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

import { styled } from '@mui/material/styles';

// import InfiniteCalendar, { Calendar, withRange } from 'react-infinite-calendar';
// import 'react-infinite-calendar/styles.css';

import reload_content from '../reload_content';

import DateDialog from './DateDialog';
import TextSearch from './TextSearch'

const DATE_FILTER_OPTIONS = [
    {
        'key': '',
        'title': 'Indifférente',
    },
    {
        'key': 'd',
        'title': 'Aujourdʼhui',
    },
    {
        'key': 'w',
        'title': 'Cette semaine',
    },
    {
        'key': 'm',
        'title': 'Ce mois ci',
    },
    {
        'key': 'p',
        'title': 'Période',
    }
]


const DateButton = styled(ButtonBase)({
    border: '1px solid #D0D0D0',
    fontSize: '12px',
    // color: 'red',
    width: '100%',
    margin: '8px 0',
    padding: 8,
    borderRadius: 4,
});


function DateRangeSelector() {

    const [isDateSelectorOpen, setDateSelectorOpen] = useState(false);
    const [dateOption, setDateOption] = useState(window.filter_config.date);

    const today = new Date(new Date().setDate(Math.ceil(new Date().getDate())));
    const weekAgo = new Date(new Date().setDate(today.getDate() - 7));

    const dateStart = window.filter_config.dateStart
        ? new Date(window.filter_config.dateStart)
        : weekAgo;

    const dateEnd =
        window.filter_config.dateEnd
        ? new Date(window.filter_config.dateEnd)
        : today;

    console.log('START:', dateStart);
    console.log('END:', dateEnd);

    const handleOpenDateSelector = useCallback(() => {
        setDateSelectorOpen(true);
    }, []);

    const handleCloseDateSelector = useCallback(() => {
        setDateSelectorOpen(false);
    }, []);

    const handleSelectOption = useCallback(e => {
            const value = e.target.value
            window.filter_config.date = value;
            setDateOption(value);
            if (value === 'p') {
                handleOpenDateSelector();
            } else {
                console.log('.faceted-search-date', 'change', value);
                reload_content();
            }
    }, []);


    const handleSubmitDateSelector = useCallback((start, end) => {
        console.log('SELECTED:', start, end);
        window.filter_config.dateStart = start;
        window.filter_config.dateEnd = end;
        setDateSelectorOpen(false);
        reload_content();
    }, []);


    return <>
        <div className="left-content-box date-wrapper">
            <h4>Date</h4>
            <div className="left-panel-content-wrap">
                <form>
                    {DATE_FILTER_OPTIONS.map(opt => (
                        <div
                            className="radio-btn"
                            key={opt.key}
                        >
                            <label>
                                <input
                                    value={opt.key || ''}
                                    checked={opt.key === dateOption ? true : null}
                                    type='radio'
                                    name='faceted-search-date'
                                    className='faceted-search-date'
                                    onChange={handleSelectOption}
                                />
                                <span className="cr"><i className="cr-icon fa fa-check"></i></span>
                                {opt.title}
                            </label>
                        </div>
                    ))}
                </form>
            </div>
        </div>

        { dateOption === 'p' ?
            <DateButton
                onClick={handleOpenDateSelector}
            >
                {window.filter_config.dateStart && window.filter_config.dateEnd ?
                    <span>
                        {
                            new Date(window.filter_config.dateStart).toLocaleDateString('fr-FR')
                        } – {
                            new Date(window.filter_config.dateEnd).toLocaleDateString('fr-FR')
                        }
                    </span>
                :
                    <span>
                        Sélectionnez la plage de dates
                    </span>
                }
            </DateButton>
        : null}

        <DateDialog
            dateStart={dateStart}
            dateEnd={dateEnd}
            isOpen={isDateSelectorOpen}
            onDismiss={handleCloseDateSelector}
            onSubmit={handleSubmitDateSelector}
        />

        <TextSearch />

    </>;
}
export default DateRangeSelector;