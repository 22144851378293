import React, { useState, useCallback } from 'react';

import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';

import { styled } from '@mui/material/styles';
// import { styled } from '@mui/material/styles';

import reload_content from '../reload_content';

const StyledTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: '#b00c30',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
    //   '& fieldset': {
    //     borderColor: 'red',
    //   },
    //   '&:hover fieldset': {
    //     borderColor: 'yellow',
    //   },
      '& label': {
        fontSize: '12px',
        fontFamily: 'Roboto',
      },
      '& input': {
          fontSize: '14px',
          fontFamily: 'Roboto',
       },
      '&.Mui-focused fieldset': {
        borderColor: '#b00c30',
      },
    },
  });

function TextSearch() {

    const [text, setText] = useState(window.filter_config.text);

    const handleSetText = useCallback(e => setText(e.target.value), []);

    const handleSearchClick = useCallback(e => {
        window.filter_config.text = text;
        reload_content();
        e.preventDefault();
        e.stopPropagation();
    }, [text]);

    return (<div className="left-content-box text-search-wrapper">
        <h4>Recherche de texte</h4>
        <form onSubmit={handleSearchClick}>
            <StyledTextField
                label='Entrez le terme de recherche' // Recherche de texte'
                // placeholder='Entrez le terme de recherche'
                variant="outlined"
                fullWidth
                size='small'
                value={text}
                onChange={handleSetText}
                InputProps={{
                    endAdornment: <InputAdornment position='end'>
                        <Tooltip arrow title='Cliquez pour rechercher du texte'>
                        <IconButton
                            type='submit'
                            // edge="end"
                        >
                            <SearchIcon />
                        </IconButton>
                        </Tooltip>
                    </InputAdornment>
                }}
            />
        </form>
    </div>);
}

export default TextSearch;