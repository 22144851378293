
import React from 'react';

import PropTypes from 'prop-types';
import VirtualList from 'react-tiny-virtual-list';

import classNames from 'classnames';
import format from 'date-fns/format';
import isAfter from 'date-fns/is_after';
import isBefore from 'date-fns/is_before';
import isSameMonth from 'date-fns/is_same_month';

import Years from 'react-infinite-calendar/lib/Years';


const SPACING = 40;
const HEADER_OVERLAP = 25;


function getMonthsForYear(year, day = 15) {
    return Array.apply(null, Array(12)).map((val, monthNum) => new Date(year, monthNum, day));
}


class CustomYears extends Years {

    renderMonths(year) {
        const {locale: {locale}, selected, theme, today, min, max, minDate, maxDate} = this.props;
        // selected.getDate() behaves weird when the selected date is the 31st because
        // for every month which has less than 31 day the month is then wrong
        const months = getMonthsForYear(year, 1); // selected.getDate());

        console.log('SELECTED:::', selected);
        console.log('MONTHS:::', months);

        return (
          <ol>
            {months.map((date, index) => {
              const isSelected = isSameMonth(date, selected);
              const isCurrentMonth = isSameMonth(date, today);
              const isDisabled = (
                isBefore(date, min) ||
                isBefore(date, minDate) ||
                isAfter(date, max) ||
                isAfter(date, maxDate)
              );
              const style = Object.assign({}, isSelected && {
                backgroundColor: (
                  typeof theme.selectionColor === 'function'
                    ? theme.selectionColor(date)
                    : theme.selectionColor
                ),
              }, isCurrentMonth && {
                borderColor: theme.todayColor,
              });

              return (
                <li
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();

                    if (!isDisabled) {
                      this.handleClick(date, e);
                    }
                  }}
                  className={classNames('Cal__Years__month', {
                    'Cal__Years__selected': isSelected,
                    'Cal__Years__currentMonth': isCurrentMonth,
                    'Cal__Years__disabled': isDisabled,
                  })}
                  style={style}
                  title={`Fixer la date au ${format(date, 'MMMM Do, YYYY')}`}
                >
                  {format(date, 'MMM', {locale})}
                </li>
              );
            })}
          </ol>
        );
      }

      render() {
        const {height, selected, showMonths, theme, today, width} = this.props;
        const currentYear = today.getFullYear();
        const years = this.props.years.slice(0, this.props.years.length);
        const selectedYearIndex = years.indexOf(selected.getFullYear());
        const rowHeight = showMonths ? 110 : 50;
        const heights = years.map((val, index) => index === 0 || index === years.length - 1
          ? rowHeight + SPACING
          : rowHeight
        );
        const containerHeight = years.length * rowHeight < height + HEADER_OVERLAP
          ? years.length * rowHeight
          : height + HEADER_OVERLAP;

        return (
          <div
            className={'Cal__Years__root'}
            style={{color: theme.selectionColor, height: height + HEADER_OVERLAP}}
          >
            <VirtualList
              ref="List"
              className={'Cal__Years__list'}
              width={width}
              height={containerHeight}
              itemCount={years.length}
              estimatedItemSize={rowHeight}
              itemSize={(index) => heights[index]}
              scrollToIndex={selectedYearIndex !== -1 ? selectedYearIndex : null}
              scrollToAlignment='center'
              renderItem={({index, style}) => {
                const year = years[index];
                const isActive = index === selectedYearIndex;

                return (
                  <div
                    key={index}
                    className={classNames('Cal__Years__year', {
                      'Cal__Years__active': !showMonths && isActive,
                      'Cal__Years__currentYear': !showMonths && year === currentYear,
                      'Cal__Years__withMonths': showMonths,
                      'Cal__Years__first': index === 0,
                      'Cal__Years__last': index === years.length - 1,
                    })}
                    onClick={() => this.handleClick(new Date(selected).setYear(year))}
                    title={`Set year to ${year}`}
                    data-year={year}
                    style={Object.assign({}, style, {
                      color: (
                        typeof theme.selectionColor === 'function'
                          ? theme.selectionColor(new Date(year, 0, 1))
                          : theme.selectionColor
                      ),
                    })}
                  >
                    <label>
                      <span
                        style={
                          !showMonths && year === currentYear
                            ? {borderColor: theme.todayColor}
                            : null
                        }
                      >
                        {year}
                      </span>
                    </label>
                    {showMonths && this.renderMonths(year)}
                  </div>
                );
              }}
            />
          </div>
        );
      }

}


export default CustomYears;