

export default function reload_tag_counts() {

    var url = '' + window.location;

    var parts = url.split('?');
    var query_str = parts[1] || '';

    if (window.hasOwnProperty('filter_config')) {
        if (window.filter_config.hasOwnProperty('edition_id')) {
            url = window.PORTAL_URL + '/' + window.filter_config.edition_id + '/@@tags_widget?' + query_str;
        }
    }

    $.getJSON(url).then(function (tag_counts) {

        console.log('TAG COUNTS:', tag_counts);

        $('.left-side-wrapper')
            .find('.tag-btn')
            .each(function () {
                var $el = $(this);
                var tag = $el.data('tag');
                var cfg = tag_counts[tag] || {};

                if (cfg.count) {

                    $el.removeClass('tag-btn-disabled').attr('title', 'Found: ' + cfg.count);
                } else if (!$el.hasClass('tag-btn-selected')) {
                    $el.addClass('tag-btn-disabled').attr('title', 'Nothing found');
                }

                $el.attr('href', cfg.url);
            });
    });
}