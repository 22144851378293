import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import InfiniteCalendar, { Calendar, withRange } from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css';

import CustomYears from './CustomYears';

const CALENDAR_THEME = {
    accentColor: '#980a29',
    selectionColor: '#b00c30',
    textColor: {
    default: '#333',
    active: '#FFF'
    },
    weekdayColor: '#b00c30',
    headerColor: '#980a29',
    floatingNav: {
    background: 'rgba(81, 67, 138, 0.96)',
    color: '#FFF',
    chevron: '#FFA726'
    }
};

class DateDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dateStart: this.props.dateStart,
            dateEnd: this.props.dateEnd,
        };
    }

    shouldComponentUpdate(nextProps) {
        return this.props.isOpen !== nextProps.isOpen;
    }

    handleCalendarSelect = e => {
        console.log('handleCalendarSelect:::', e.start, e.end);
        this.setState({
            dateStart: e.start,
            dateEnd: e.end,
        });
    };

    handleSubmit = () => {
        this.props.onSubmit(this.state.dateStart, this.state.dateEnd);
    };

    render() {
        return (
            <Dialog
                open={this.props.isOpen}
                onClose={this.props.onDismiss}
                scroll='body'
                maxWidth='xl'
            >

                <InfiniteCalendar
                    Component={withRange(Calendar)}
                    YearsComponent={CustomYears}
                    selected={{
                        start: this.state.dateStart,
                        end: this.state.dateEnd,
                    }}
                    width={450}
                    height={400}
                    rowHeight={54}

                    theme={CALENDAR_THEME}

                    locale={{
                        locale: require('date-fns/locale/fr'),
                        headerFormat: 'dddd, D MMM',
                        weekdays: ["Dim","Lun","Mar","Mer","Jeu","Ven","Sam"],
                        blank: 'Aucune date selectionnee',
                        todayLabel: {
                         long: 'Aujourd\'hui',
                         short: 'Auj.'
                        }
                    }}

                    onSelect={this.handleCalendarSelect}
                />

                <DialogActions>
                    <Button onClick={this.props.onDismiss} color='inherit'>Annuler</Button>
                    <Button onClick={this.handleSubmit}  variant="outlined">Enregistrer</Button>
                </DialogActions>

            </Dialog>
        );
    }
}

export default DateDialog;