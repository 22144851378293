export default function filter_url() {
    var url = window.PORTAL_URL;
    var config = window.filter_config;

    url += '/' + config.edition_id;
    if (config.feature_id) {
        url += '/' + config.feature_id;
    }

    url += '?_';

    if (config.tags && config.tags.length) {
        url += '&tags=' + encodeURIComponent(config.tags.join('|'));
    }

    if (config.regions && config.regions.length) {
        url += '&regions=' + encodeURIComponent(config.regions.join('|'));
    }

    if (config.lieu && config.lieu.length) {
        url += '&lieu=' + encodeURIComponent(config.lieu.join('|'));
    }

    if (config.date) {
        url += '&date=' + config.date;

        if (config.date === 'p') {
            const today = new Date(new Date().setDate(Math.ceil(new Date().getDate())));
            const weekAgo = new Date(new Date().setDate(today.getDate() - 7));

            const dateStart = config.dateStart
                ? new Date(config.dateStart)
                : weekAgo;
            const dateEnd = config.dateEnd
                ? new Date(config.dateEnd)
                : today;

            console.log('DATE START:', dateStart, 'ISO:', dateStart.toISOString());

            // en-CA locale returns the date in YYYY-MM-DD format
            url += '&dateStart=' + dateStart.toLocaleDateString('en-CA');
            url += '&dateEnd=' + dateEnd.toLocaleDateString('en-CA');

        }
    }

    if (config.text) {
        url += '&text=' + encodeURIComponent(config.text);
    }

    return url;
}
