
import filter_url from './filter_url';

export default function reload_content() {
    var url = filter_url();
    if (window.location != url) {
        window.history.pushState({}, '', url);
    }
    $('#content-container').load(url + ' #content');
    window.scrollTo({ top: 0, behavior: 'smooth' });
}
