
import React from 'react';
import ReactDOM from 'react-dom';

import DateRangeSelector from './DateRangeSelector';


function mount(container_el) {
    const component = <DateRangeSelector />;
    ReactDOM.render(component, container_el);
}

export default mount;