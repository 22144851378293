
import mountDateSelector from './DateRangeSelector/mount';
import reload_tag_counts from './reload_tag_counts';
import filter_url from './filter_url';
import reload_content from './reload_content';
//import {tns} from 'tiny-slider';
// import { bottomNavigationActionClasses } from '@mui/material';
// require(
//     ['jquery'],
//     function (module, $) {


function reload_favourites() {
    var url = window.location;

    if ($('#x-favourites-block').length) {
        $('#x-favourites-block').load(url + ' #x-favourites-block');
    }

    if ($('#x-favourites-list').length) {
        $('#x-favourites-list').load(url + ' #x-favourites-list');
    }

}


$(function () {

    // Mount date range selector
    const el = document.getElementById('date-range-selector-container');
    if (el) {
        mountDateSelector(el);
    }

    // refresh on initial load
    reload_tag_counts('' + window.location);

    // features
    $('body').off('click', '.feature-btn').on('click', '.feature-btn', function (e) {

        window.filter_config.feature_id = $(this).data('feature_id');

        $('.feature-btn-selected').removeClass('feature-btn-selected');
        $(this).addClass('feature-btn-selected');

        $('.left-side-wrapper')
            .find('.tag-btn')
            .removeClass('tag-btn-selected')
            .addClass('tag-btn-disabled');

        reload_content();
        reload_tag_counts();

        e.preventDefault();
        return false;
    });


    // tags (themes)
    $('body').off('click', '.tag-btn').on('click', '.tag-btn', function (e) {

        var $el = $(this);

        if ($el.hasClass('not-a-tag')) {
            return;
        }

        var tag = $el.data('tag');
        var current_tags = window.filter_config.tags || [];
        var idx = current_tags.indexOf(tag);
        if (idx !== -1) {
            console.log('Removing', tag);
            current_tags.splice(idx, 1);
            console.log('Removed:', current_tags);
        } else {
            console.log('Adding', tag);
            current_tags.push(tag);
        }
        window.filter_config.tags = current_tags;

        // there's a button in the listing and in the left column,
        // when removing the tag from the listing we need to also toggle
        // the one in the left column
        var $buttons_to_toggle = $('a.tag-btn[data-tag="' + tag + '"]');

        if ($el.hasClass('tag-btn-selected')) {
            $buttons_to_toggle.removeClass('tag-btn-selected');
        } else {
            $buttons_to_toggle.addClass('tag-btn-selected');
        }

        reload_content();
        reload_tag_counts();

        e.preventDefault();
        return false;
    });


    $('#department-select').on('change', function (e) {

        var $sel = $(this);
        var val = $sel.val();
        window.filter_config.regions = val || [];
        console.log('#department-select', 'change', val);
        reload_content();
        e.preventDefault();
        return false;
    });

    //
    $('body')
    .off('click', '.article-content-wrap')
    .on('click', '.article-content-wrap', function (e) {
        var $div = $(this);
        var $link = $div.find('a');
        if ($link.length) {
            window.location = $link.attr('href');
            return false;
        }
    });


    $('body')
    .off('click', '.article-content-wrap')
    .on('click', '.article-content-wrap', function (e) {
        var $div = $(this);
        var $link = $div.find('a');
        if ($link.length) {
            window.location = $link.attr('href');
            return false;
        }
    });

    $('body')
    .off('click', '.x-star')
    .on('click', '.x-star', function (e) {
        var $btn = $(this);
        var $link = $btn.parents('.article-content-wrap').find('a');
        if ($link.length) {
            var href = $link.attr('href');
            if ($btn.hasClass('active')) {
                // Remove the star
                $btn.removeClass('active');
                $.post(href + '/@@remove-star').then(function () {
                    // reload_content();
                    // location.reload();
                    reload_favourites();
                }).fail(function () {
                    $btn.addClass('active');
                });
            } else {
                // Add a star
                $btn.addClass('active');
                $.post(href + '/@@add-star').then(function () {
                    // reload_content();
                    // location.reload();
                    reload_favourites();
                }).fail(function () {
                    $btn.removeClass('active');
                });
            }
        }

        e.preventDefault();
        return false;
    });

    // See #18412 - Improvements of display for unauthenticated users
    $('.anonymousOverlay').each(function () {
        $(this).find('p').css('margin-top', ($(this).height() - 30) / 2);
    });

    $('.anonymousOverlay').click(function (e) {
        window.location.href = '/membres/signup';
        e.stopPropagation();
        e.preventDefault();
        return false;
    });
});
